import moment from 'moment'

const capitalize = function(value) {
  if (!value) return ''
  return value.toUpperCase()
}

const capitalizeFirstLetter = function(value) {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const formatDashToWhitespace = function(value) {
  if (!value) return ''
  if (value.indexOf('_') < 0 && value.indexOf('-') < 0) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
  const words = value.replace(/_|-/g, ' ')
  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase()
}

const formatPrice = function(value) {
  if (!value) return 0
  if (value === 0) return 0
  value = value / 100
  return value
}

const formatNumber = function(value) {
  if (!value || value === 0) return 0
  return value.toLocaleString()
}

const formatDate = function(value) {
  if (!value) return ''
  value = moment(value).format('YYYY-MM-DD')
  return value
}

const formatTime = function(value) {
  if (!value) return ''
  return moment(value).fromNow()
}

const formatDateDM = function(value) {
  if (!value) return ''
  value = moment(value).format('DD.MM.YYYY')
  return value
}

const formatDateInString = function(value) {
  if (!value) return ''
  return moment(value).fromNow()
}

const formatCurrency = function(value) {
  if (!value) return ''
  if (value === 'EUR') {
    return '€'
  } else {
    return value
  }
}

export {
  capitalize,
  capitalizeFirstLetter,
  formatDashToWhitespace,
  formatDate,
  formatDateDM,
  formatPrice,
  formatNumber,
  formatTime,
  formatDateInString,
  formatCurrency
}
