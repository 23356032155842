import { tldExists } from 'tldjs'

const validateEmail = (email) => {
  if (!email) return false
  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  // extract tld domain
  var domain = email.substr(String(email).indexOf('@') + 1)
  // check if email has email format and that tld domain is a valid domain
  return re.test(String(email).toLowerCase()) && tldExists(domain) //=> true
}
const validatePassword = (password) => {
  if (!password) return false
  var re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?!.*\s).{8,})/

  if (password.match(re)) {
    return true
  } else {
    return false
  }
}

export { validateEmail, validatePassword }
