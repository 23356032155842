const chargeVAT = (code) => {
  switch (code) {
    case 'U25':
      return true
    case 'UY0':
      return false
    case 'Abr':
      return false
    default:
      return false
  }
}

export { chargeVAT }
